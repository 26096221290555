<template>
  <div class="app-group-setting">
    <b-card>
      <b-form
        :class="{ 'invalid-form': isSubmitted }"
        @submit.prevent
      >
        <b-row>
          <b-col md="4">
            <input-group
              v-model="dataInfo.name"
              :value="dataInfo.name"
              :label="'Group Name'"
              image="/images/members/groupName.svg"
              :make-required="true"
            />
          </b-col>
          <b-col md="4">
            <select-group
              v-model="dataInfo.countryId"
              :value="dataInfo.countryId"
              :label="'Country'"
              :options="$manMudra.constants.country"
              option-value="value"
              option-label="text"
              image="/images/members/country.svg"
            />
          </b-col>
          <b-col md="4">
            <input-group
              v-model="dataInfo.urlSuffix"
              :value="dataInfo.urlSuffix"
              :label="'URL Suffix'"
              image="/images/members/urlSuffix.svg"
              :make-required="true"
            />
          </b-col>
          <b-col md="4">
            <select-group
              v-model="dataInfo.internationalMember"
              :value="dataInfo.internationalMember"
              :label="'International Members?'"
              :options="$manMudra.constants.internationalMember"
              option-value="value"
              option-label="text"
              image="/images/members/international.svg"
            />
          </b-col>
          <b-col md="4">
            <input-group
              v-model="dataInfo.govtRegNo"
              :value="dataInfo.govtRegNo"
              :label="'Govt. Registration No.'"
              image="/images/members/govReg.svg"
            />
          </b-col>
          <b-col md="4">
            <input-group
              v-model="dataInfo.custom1"
              :value="dataInfo.custom1"
              :label="'Custom 1'"
              image="/images/members/custom.svg"
            />
          </b-col>
          <b-col md="4">
            <input-group
              v-model="dataInfo.custom2"
              :value="dataInfo.custom2"
              :label="'Custom 2'"
              image="/images/members/custom.svg"
            />
          </b-col>
          <b-col md="4">
            <input-group
              v-model="dataInfo.custom3"
              :value="dataInfo.custom3"
              :label="'Custom 3'"
              image="/images/members/custom.svg"
            />
          </b-col>
          <b-col md="4">
            <select-group
              v-model="dataInfo.isActive"
              :value="dataInfo.isActive"
              :label="'Status'"
              :options="$manMudra.constants.isActiveMember"
              option-value="value"
              option-label="text"
              image="/images/members/status.svg"
            />
          </b-col>
          <b-col md="12">
            <app-collapse
              accordion
              type="margin"
              class="mt-2"
            >
              <app-collapse-item
                :title="'Admin'"
                class="bg-cu-blue bo-blue"
              >
                <b-row>
                  <b-col md="4">
                    <input-group
                      v-model="dataInfo.superUserName"
                      :value="dataInfo.superUserName"
                      :label="'Superuser Name'"
                      image="/images/members/superUser.svg"
                      :disabled="'true'"
                    />
                  </b-col>
                  <b-col md="4">
                    <input-group
                      v-model="dataInfo.superUserMobile"
                      :value="dataInfo.superUserMobile"
                      :label="'Superuser Mobile'"
                      image="/images/members/mobile.svg"
                      :disabled="'true'"
                    />
                  </b-col>
                  <b-col md="4">
                    <input-group
                      v-model="dataInfo.superUserEmail"
                      :value="dataInfo.superUserEmail"
                      :label="'Superuser Email'"
                      image="/images/members/email.svg"
                      :disabled="'true'"
                    />
                  </b-col>
                  <b-col md="4">
                    <input-group
                      v-model="dataInfo.mainUserName"
                      :value="dataInfo.mainUserName"
                      :label="'Main Officer Name'"
                      image="/images/members/mainOfficer.svg"
                    />
                  </b-col>
                  <b-col md="4">
                    <input-group
                      v-model="dataInfo.mainUserMobile"
                      :value="dataInfo.mainUserMobile"
                      :label="'Main Officer Mobile'"
                      image="/images/members/mobile.svg"
                    />
                  </b-col>
                  <b-col md="4">
                    <input-group
                      v-model="dataInfo.mainUserEmail"
                      :value="dataInfo.mainUserEmail"
                      :label="'Main Officer Email'"
                      image="/images/members/email.svg"
                    />
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                :title="'Address'"
                class="bg-yellow bo-yellow"
              >
                <b-row>
                  <b-col md="4">
                    <select-group
                      v-model="dataInfo.address.stateId"
                      :value="dataInfo.address.stateId"
                      :label="'State'"
                      :options="state"
                      option-value="id"
                      option-label="name"
                      image="/images/members/state.svg"
                      @update="getDistricts('yes')"
                    />
                  </b-col>
                  <b-col md="4">
                    <select-group
                      v-model="dataInfo.address.districtId"
                      :value="dataInfo.address.districtId"
                      :label="'District'"
                      :options="district"
                      option-value="id"
                      option-label="name"
                      image="/images/members/district.svg"
                      @update="
                        getSubDistricts('yes');
                        getBlock('yes');
                      "
                    />
                  </b-col>
                  <b-col md="4">
                    <select-group
                      v-model="dataInfo.address.subDistrictId"
                      :value="dataInfo.address.subDistrictId"
                      :label="'Tehsil/Taluk'"
                      :options="taluk"
                      option-value="id"
                      option-label="name"
                      image="/images/members/tahsil.svg"
                    />
                  </b-col>
                  <b-col md="4">
                    <select-group
                      v-model="dataInfo.address.blockId"
                      :value="dataInfo.address.blockId"
                      :label="'Block'"
                      :options="block"
                      option-value="id"
                      option-label="name"
                      image="/images/members/block.svg"
                      @update="getPanchayat('yes')"
                    />
                  </b-col>
                  <b-col md="4">
                    <select-group
                      v-model="dataInfo.address.panchayatId"
                      :value="dataInfo.address.panchayatId"
                      :label="'Panchayat'"
                      :options="panchayat"
                      option-value="id"
                      option-label="name"
                      image="/images/members/panchayat.svg"
                    />
                  </b-col>
                  <b-col md="4">
                    <input-group
                      v-model="dataInfo.address.cityOrVillage"
                      :value="dataInfo.address.cityOrVillage"
                      :label="'City / Village'"
                      image="/images/members/village.svg"
                      :type="'text'"
                    />
                  </b-col>
                  <b-col md="4">
                    <input-group
                      v-model="dataInfo.address.pinCode"
                      :value="dataInfo.address.pinCode"
                      :label="'Pin Code'"
                      image="/images/members/pinCode.svg"
                      :type="'number'"
                    />
                  </b-col>
                  <b-col md="4">
                    <input-group
                      v-model="dataInfo.address.email"
                      :value="dataInfo.address.email"
                      :label="'Email'"
                      image="/images/members/email.svg"
                      :type="'text'"
                    />
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                :title="'Accounting, Fees, Banks'"
                class="bg-orange bo-orange"
              >
                <b-row>
                  <b-col md="4">
                    <select-group
                      v-model="dataInfo.feeInterval"
                      :value="dataInfo.feeInterval"
                      :label="'Interval'"
                      :options="period"
                      option-value="id"
                      option-label="name"
                      image="/images/members/feesInterval.svg"
                      :make-required="true"
                    />
                  </b-col>
                  <b-col md="4">
                    <select-group
                      v-model="dataInfo.feeOf"
                      :value="dataInfo.feeOf"
                      :label="'Fees Period'"
                      :options="years"
                      option-value="id"
                      option-label="name"
                      image="/images/members/fees.svg"
                      :rules="$helpers.required"
                    />
                  </b-col>
                  <b-col md="4">
                    <select-group
                      v-model="dataInfo.accountingYearStartsOn"
                      :value="dataInfo.accountingYearStartsOn"
                      :label="'Accounting Year Start Month'"
                      :options="year"
                      option-value="id"
                      option-label="name"
                      image="/images/members/acctStartMonth.svg"
                      :make-required="true"
                    />
                  </b-col>
                  <b-col md="4">
                    <input-group
                      v-model="dataInfo.fee"
                      :value="dataInfo.fee"
                      :label="'Amount'"
                      image="/images/members/amount.svg"
                      :type="'number'"
                      :make-required="true"
                    />
                  </b-col>
                  <b-col md="4">
                    <input-group
                      v-model="dataInfo.receiptPrefix"
                      :value="dataInfo.receiptPrefix"
                      :label="'Auto-Incrementing Starting Receipt No.'"
                      :type="'number'"
                    />
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                :title="'Member Fields'"
                class="bg-deep-pink bo-pink"
              >
                <b-row>
                  <b-col cols="9">
                    <b>Field</b>
                  </b-col>
                  <b-col
                    cols="3"
                    class="text-right"
                  >
                    <b>Visible</b>
                  </b-col>
                  <b-col
                    cols="9"
                    class="pt-1"
                  >
                    <b-img
                      src="/images/members/accoAddress.svg"
                      height="14"
                      class="md-pl"
                    />Address
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_show_address_fields"
                      v-model="dataInfo.showAddressFields"
                    />
                  </b-col>
                  <b-col
                    cols="9"
                    class="pt-1"
                  >
                    <b-img
                      src="/images/members/accoWork.svg"
                      height="14"
                      class="md-pl"
                    />Work
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_show_work_fields"
                      v-model="dataInfo.showWorkFields"
                    />
                  </b-col>
                  <b-col
                    cols="9"
                    class="pt-1"
                  >
                    <b-img
                      src="/images/members/accoSocial.svg"
                      height="14"
                      class="md-pl"
                    />Socioeconomic
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_show_social_fields"
                      v-model="dataInfo.showSocialFields"
                    />
                  </b-col>
                  <b-col
                    cols="9"
                    class="pt-1"
                  >
                    <b-img
                      src="/images/members/accoCardNos.svg"
                      height="14"
                      class="md-pl"
                    />Card Numbers
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_show_economic_fields"
                      v-model="dataInfo.showEconomicFields"
                    />
                  </b-col>
                  <b-col
                    cols="9"
                    class="pt-1"
                  >
                    <b-img
                      src="/images/members/accoHealthEdu.svg"
                      height="14"
                      class="md-pl"
                    />Health & Education
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_show_health_fields"
                      v-model="dataInfo.showHealthEduFields"
                    />
                  </b-col>
                  <b-col
                    cols="9"
                    class="pt-1"
                  >
                    <b-img
                      src="/images/members/accoMembership.svg"
                      height="14"
                      class="md-pl"
                    />Membership
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_show_membership_fields"
                      v-model="dataInfo.showMembership"
                    />
                  </b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col cols="6">
                    <b>Field</b>
                  </b-col>
                  <b-col
                    cols="3"
                    class="text-right"
                  >
                    <b>Visible</b>
                  </b-col>
                  <b-col
                    cols="3"
                    class="text-right"
                  >
                    <b>Required</b>
                  </b-col>
                  <b-col
                    cols="6"
                    class="pt-1"
                  >
                    <b-img
                      src="/images/members/name.svg"
                      height="14"
                      class="md-pl"
                    />Name
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_name_visible"
                      v-model="dataInfo.validations.nameVisible"
                      @change="sectionVisibilityChanged('nameVisible')"
                    />
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_show_name_fields"
                      v-model="dataInfo.validations.nameRequired"
                      :disabled="!dataInfo.validations.nameVisible"
                    />
                  </b-col>
                  <b-col
                    cols="6"
                    class="pt-1"
                  >
                    <b-img
                      src="/images/members/surname.svg"
                      height="14"
                      class="md-pl"
                    />Surname
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_surname_visible"
                      v-model="dataInfo.validations.surnameVisible"
                      @change="sectionVisibilityChanged('surnameVisible')"
                    />
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_surname_required"
                      v-model="dataInfo.validations.surnameRequired"
                      :disabled="!dataInfo.validations.surnameVisible"
                    />
                  </b-col>
                  <b-col
                    cols="6"
                    class="pt-1"
                  >
                    <b-img
                      src="/images/members/spouse.svg"
                      height="14"
                      class="md-pl"
                    />Father / Husband
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_relation_visible"
                      v-model="dataInfo.validations.relationVisible"
                      @change="sectionVisibilityChanged('relationVisible')"
                    />
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_relation_required"
                      v-model="dataInfo.validations.relationRequired"
                      :disabled="!dataInfo.validations.relationVisible"
                    />
                  </b-col>
                  <b-col
                    cols="6"
                    class="pt-1"
                  >
                    <b-img
                      src="/images/members/organisation.svg"
                      height="14"
                      class="md-pl"
                    />Organization
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_organization_visible"
                      v-model="dataInfo.validations.organizationVisible"
                      @change="sectionVisibilityChanged('organizationVisible')"
                    />
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_organization_required"
                      v-model="dataInfo.validations.organizationRequired"
                      :disabled="!dataInfo.validations.organizationVisible"
                    />
                  </b-col>
                  <b-col
                    cols="6"
                    class="pt-1"
                  >
                    <b-img
                      src="/images/members/otherPh.svg"
                      height="14"
                      class="md-pl"
                    />Other Phone
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_landline_phone_visible"
                      v-model="dataInfo.validations.landlinePhoneVisible"
                      @change="sectionVisibilityChanged('landlinePhoneVisible')"
                    />
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_landline_phone_required"
                      v-model="dataInfo.validations.landlinePhoneRequired"
                      :disabled="!dataInfo.validations.landlinePhoneVisible"
                    />
                  </b-col>
                  <b-col
                    cols="6"
                    class="pt-1"
                  >
                    <b-img
                      src="/images/members/sex.svg"
                      height="14"
                      class="md-pl"
                    />Sex
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_sex_visible"
                      v-model="dataInfo.validations.sexVisible"
                      @change="sectionVisibilityChanged('sexVisible')"
                    />
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_sex_required"
                      v-model="dataInfo.validations.sexRequired"
                      :disabled="!dataInfo.validations.sexVisible"
                    />
                  </b-col>
                  <b-col
                    cols="6"
                    class="pt-1"
                  >
                    <b-img
                      src="/images/members/marital.svg"
                      height="14"
                      class="md-pl"
                    />Marital
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_marital_visible"
                      v-model="dataInfo.validations.maritalVisible"
                      @change="sectionVisibilityChanged('maritalVisible')"
                    />
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_show_marital_fields"
                      v-model="dataInfo.validations.maritalRequired"
                      :disabled="!dataInfo.validations.maritalVisible"
                    />
                  </b-col>
                  <b-col
                    cols="6"
                    class="pt-1"
                  >
                    <b-img
                      src="/images/members/age.svg"
                      height="14"
                      class="md-pl"
                    />Age
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_age_visible"
                      v-model="dataInfo.validations.ageVisible"
                      @change="sectionVisibilityChanged('ageVisible')"
                    />
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_age_required"
                      v-model="dataInfo.validations.ageRequired"
                      :disabled="!dataInfo.validations.ageVisible"
                    />
                  </b-col>
                  <b-col
                    cols="6"
                    class="pt-1"
                  >
                    <b-img
                      src="/images/members/dateOfBirth.svg"
                      height="14"
                      class="md-pl"
                    />Date of Birth
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_dateOfBirth_visible"
                      v-model="dataInfo.validations.dateOfBirthVisible"
                      @change="sectionVisibilityChanged('dateOfBirthVisible')"
                    />
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_dateOfBirth_required"
                      v-model="dataInfo.validations.dateOfBirthRequired"
                      :disabled="!dataInfo.validations.dateOfBirthVisible"
                    />
                  </b-col>
                  <b-col
                    cols="6"
                    class="pt-1"
                  >
                  Age or Date of Birth
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_ageOrDateOfBirth_visible"
                      v-model="dataInfo.validations.ageOrDateOfBirthVisible"
                      @change="sectionVisibilityChanged('ageOrDateOfBirthVisible')"
                    />
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_ageOrDateOfBirth_required"
                      v-model="dataInfo.validations.ageOrDateOfBirthRequired"
                      :disabled="!dataInfo.validations.ageOrDateOfBirthVisible"
                    />
                  </b-col>
                  <b-col
                    cols="6"
                    class="pt-1"
                  >
                    <b-img
                      src="/images/members/aadhaar.svg"
                      height="14"
                      class="md-pl"
                    />Aadhaar
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_aadhar_visible"
                      v-model="dataInfo.validations.aadharVisible"
                      @change="sectionVisibilityChanged('aadharVisible')"
                    />
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_aadhar_required"
                      v-model="dataInfo.validations.aadharRequired"
                      :disabled="!dataInfo.validations.aadharVisible"
                    />
                  </b-col>
                  <b-col
                    cols="6"
                    class="pt-1"
                  >
                    <b-img
                      src="/images/members/mobile.svg"
                      height="14"
                      class="md-pl"
                    />Mobile
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_mobile_visible"
                      v-model="dataInfo.validations.mobileVisible"
                      @change="sectionVisibilityChanged('mobileVisible')"
                    />
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_mobile_required"
                      v-model="dataInfo.validations.mobileRequired"
                      :disabled="!dataInfo.validations.mobileVisible"
                    />
                  </b-col>
                  <b-col
                    cols="6"
                    class="pt-1"
                  >
                    Aadhaar or Mobile
                  </b-col>
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  />
                  <b-col
                    cols="3"
                    class="md-display-flex-end pt-1"
                  >
                    <b-form-checkbox
                      id="group_setting_mobile_visible"
                      v-model="dataInfo.validations.aadharOrMobile"
                    />
                  </b-col>
                  <b-col md="12">
                    <app-collapse-item
                      :title="'Address'"
                      class="bg-lime bo-lime"
                    >
                      <b-row>
                        <b-col cols="6">
                          <b>Field</b>
                        </b-col>
                        <b-col
                          cols="3"
                          class="text-right"
                        >
                          <b>Visible</b>
                        </b-col>
                        <b-col
                          cols="3"
                          class="text-right"
                        >
                          <b>Required</b>
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/state.svg"
                            height="14"
                            class="md-pl"
                          />State
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_state_visible"
                            v-model="dataInfo.validations.stateVisible"
                            @change="sectionVisibilityChanged('stateVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_state_required"
                            v-model="dataInfo.validations.stateRequired"
                            :disabled="!dataInfo.validations.stateVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/district.svg"
                            height="14"
                            class="md-pl"
                          />District
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_district_visible"
                            v-model="dataInfo.validations.districtVisible"
                            @change="sectionVisibilityChanged('districtVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_district_required"
                            v-model="dataInfo.validations.districtRequired"
                            :disabled="!dataInfo.validations.districtVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/tahsil.svg"
                            height="14"
                            class="md-pl"
                          />Tahsil/Taluk
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_tahsil_visible"
                            v-model="dataInfo.validations.tahsilVisible"
                            @change="sectionVisibilityChanged('tahsilVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_tahsil_required"
                            v-model="dataInfo.validations.tahsilRequired"
                            :disabled="!dataInfo.validations.tahsilVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/block.svg"
                            height="14"
                            class="md-pl"
                          />Block
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_block_visible"
                            v-model="dataInfo.validations.blockVisible"
                            @change="sectionVisibilityChanged('blockVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_block_required"
                            v-model="dataInfo.validations.blockRequired"
                            :disabled="!dataInfo.validations.blockVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/panchayat.svg"
                            height="14"
                            class="md-pl"
                          />Panchayat
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_panchayat_visible"
                            v-model="dataInfo.validations.panchayatVisible"
                            @change="sectionVisibilityChanged('panchayatVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_panchayat_required"
                            v-model="dataInfo.validations.panchayatRequired"
                            :disabled="!dataInfo.validations.panchayatVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/village.svg"
                            height="14"
                            class="md-pl"
                          />Town / Village
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_townVillage_visible"
                            v-model="dataInfo.validations.townVillageVisible"
                            @change="sectionVisibilityChanged('townVillageVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_townVillage_required"
                            v-model="dataInfo.validations.townVillageRequired"
                            :disabled="!dataInfo.validations.townVillageVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/block.svg"
                            height="14"
                            class="md-pl"
                          />Pin Code
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_pincode_visible"
                            v-model="dataInfo.validations.pincodeVisible"
                            @change="sectionVisibilityChanged('pincodeVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_pincode_required"
                            v-model="dataInfo.validations.pincodeRequired"
                            :disabled="!dataInfo.validations.pincodeVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/houseNo.svg"
                            height="14"
                            class="md-pl"
                          />House No. Road
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_houseNo_visible"
                            v-model="dataInfo.validations.houseNoVisible"
                            @change="sectionVisibilityChanged('houseNoVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_houseNo_required"
                            v-model="dataInfo.validations.houseNoRequired"
                            :disabled="!dataInfo.validations.houseNoVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/locality.svg"
                            height="14"
                            class="md-pl"
                          />Locality
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_locality_visible"
                            v-model="dataInfo.validations.localityVisible"
                            @change="sectionVisibilityChanged('localityVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_locality_required"
                            v-model="dataInfo.validations.localityRequired"
                            :disabled="!dataInfo.validations.localityVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/postOffice.svg"
                            height="14"
                            class="md-pl"
                          />Block
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_postOffice_visible"
                            v-model="dataInfo.validations.postOfficeVisible"
                            @change="sectionVisibilityChanged('postOfficeVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_postOffice_required"
                            v-model="dataInfo.validations.postOfficeRequired"
                            :disabled="!dataInfo.validations.postOfficeVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/email.svg"
                            height="14"
                            class="md-pl"
                          />Email
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_email_visible"
                            v-model="dataInfo.validations.emailVisible"
                            @change="sectionVisibilityChanged('emailVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_email_required"
                            v-model="dataInfo.validations.emailRequired"
                            :disabled="!dataInfo.validations.emailVisible"
                          />
                        </b-col>
                      </b-row>
                    </app-collapse-item>
                    <app-collapse-item
                      :title="'Socioeconomic'"
                      class="bg-cyan bo-cyan"
                    >
                      <b-row>
                        <b-col cols="6">
                          <b>Field</b>
                        </b-col>
                        <b-col
                          cols="3"
                          class="text-right"
                        >
                          <b>Visible</b>
                        </b-col>
                        <b-col
                          cols="3"
                          class="text-right"
                        >
                          <b>Required</b>
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/category.svg"
                            height="14"
                            class="md-pl"
                          />Category
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_category_visible"
                            v-model="dataInfo.validations.categoryVisible"
                            @change="sectionVisibilityChanged('categoryVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_category_required"
                            v-model="dataInfo.validations.categoryRequired"
                            :disabled="!dataInfo.validations.categoryVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/caste.svg"
                            height="14"
                            class="md-pl"
                          />Caste
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_caste_visible"
                            v-model="dataInfo.validations.casteVisible"
                            @change="sectionVisibilityChanged('casteVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_caste_required"
                            v-model="dataInfo.validations.casteRequired"
                            :disabled="!dataInfo.validations.casteVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/accountNo.svg"
                            height="14"
                            class="md-pl"
                          />Bank Account No.
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_bank_visible"
                            v-model="dataInfo.validations.bankVisible"
                            @change="sectionVisibilityChanged('bankVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_bank_required"
                            v-model="dataInfo.validations.bankRequired"
                            :disabled="!dataInfo.validations.bankVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/ifsc.svg"
                            height="14"
                            class="md-pl"
                          />IFSC
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_ifsc_visible"
                            v-model="dataInfo.validations.ifscVisible"
                            @change="sectionVisibilityChanged('ifscVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_ifsc_required"
                            v-model="dataInfo.validations.ifscRequired"
                            :disabled="!dataInfo.validations.ifscVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/incomeCat.svg"
                            height="14"
                            class="md-pl"
                          />Income Category
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_incomeCat_visible"
                            v-model="dataInfo.validations.incomeCatVisible"
                            @change="sectionVisibilityChanged('incomeCatVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_incomeCat_required"
                            v-model="dataInfo.validations.incomeCatRequired"
                            :disabled="!dataInfo.validations.incomeCatVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/children.svg"
                            height="14"
                            class="md-pl"
                          />Underage Children
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_underage_visible"
                            v-model="dataInfo.validations.underageVisible"
                            @change="sectionVisibilityChanged('underageVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_underage_required"
                            v-model="dataInfo.validations.underageRequired"
                            :disabled="!dataInfo.validations.underageVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/employed.svg"
                            height="14"
                            class="md-pl"
                          />Earning Adults
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_earnAdults_visible"
                            v-model="dataInfo.validations.earnAdultsVisible"
                            @change="sectionVisibilityChanged('earnAdultsVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_earnAdults_required"
                            v-model="dataInfo.validations.earnAdultsRequired"
                            :disabled="!dataInfo.validations.earnAdultsVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/unemployed.svg"
                            height="14"
                            class="md-pl"
                          />Non-Earning Adults
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_nonEarning_visible"
                            v-model="dataInfo.validations.nonEarningVisible"
                            @change="sectionVisibilityChanged('nonEarningVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_nonEarning_required"
                            v-model="dataInfo.validations.nonEarningRequired"
                            :disabled="!dataInfo.validations.nonEarningVisible"
                          />
                        </b-col>
                      </b-row>
                    </app-collapse-item>
                    <app-collapse-item
                      :title="'Card Numbers'"
                      class="bg-cu-orange bo-cu-orange"
                    >
                      <b-row>
                        <b-col cols="6">
                          <b>Field</b>
                        </b-col>
                        <b-col
                          cols="3"
                          class="text-right"
                        >
                          <b>Visible</b>
                        </b-col>
                        <b-col
                          cols="3"
                          class="text-right"
                        >
                          <b>Required</b>
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/rationType.svg"
                            height="14"
                            class="md-pl"
                          />Ration Card Type
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_rationType_visible"
                            v-model="dataInfo.validations.rationTypeVisible"
                            @change="sectionVisibilityChanged('rationTypeVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_rationType_required"
                            v-model="dataInfo.validations.rationTypeRequired"
                            :disabled="!dataInfo.validations.rationTypeVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/rationNo.svg"
                            height="14"
                            class="md-pl"
                          />Ration Card No.
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_rationNo_visible"
                            v-model="dataInfo.validations.rationNoVisible"
                            @change="sectionVisibilityChanged('rationNoVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_rationNo_required"
                            v-model="dataInfo.validations.rationNoRequired"
                            :disabled="!dataInfo.validations.rationNoVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/pensionNo.svg"
                            height="14"
                            class="md-pl"
                          />Pension Pay Order
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_pensionNo_visible"
                            v-model="dataInfo.validations.pensionNoVisible"
                            @change="sectionVisibilityChanged('pensionNoVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_pensionNo_required"
                            v-model="dataInfo.validations.pensionNoRequired"
                            :disabled="!dataInfo.validations.pensionNoVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/jobCard.svg"
                            height="14"
                            class="md-pl"
                          />Job Card No.
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_jobCard_visible"
                            v-model="dataInfo.validations.jobCardVisible"
                            @change="sectionVisibilityChanged('jobCardVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_jobCard_required"
                            v-model="dataInfo.validations.jobCardRequired"
                            :disabled="!dataInfo.validations.jobCardVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/janAadhaar.svg"
                            height="14"
                            class="md-pl"
                          />Jan Aadhaar No.
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_janAadhar_visible"
                            v-model="dataInfo.validations.janAadharVisible"
                            @change="sectionVisibilityChanged('janAadharVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_janAadhar_required"
                            v-model="dataInfo.validations.janAadharRequired"
                            :disabled="!dataInfo.validations.janAadharVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/shramikNo.svg"
                            height="14"
                            class="md-pl"
                          />Shramik Diary No.
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_shramikNo_visible"
                            v-model="dataInfo.validations.shramikNoVisible"
                            @change="sectionVisibilityChanged('shramikNoVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_shramikNo_required"
                            v-model="dataInfo.validations.shramikNoRequired"
                            :disabled="!dataInfo.validations.shramikNoVisible"
                          />
                        </b-col>
                      </b-row>
                    </app-collapse-item>
                    <app-collapse-item
                      :title="'Work'"
                      class="bg-cu-purple bo-cu-purple"
                    >
                      <b-row>
                        <b-col cols="6">
                          <b>Field</b>
                        </b-col>
                        <b-col
                          cols="3"
                          class="text-right"
                        >
                          <b>Visible</b>
                        </b-col>
                        <b-col
                          cols="3"
                          class="text-right"
                        >
                          <b>Required</b>
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/workType.svg"
                            height="14"
                            class="md-pl"
                          />Type of Work
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_typeOfWork_visible"
                            v-model="dataInfo.validations.typeOfWorkVisible"
                            @change="sectionVisibilityChanged('typeOfWorkVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_typeOfWork_required"
                            v-model="dataInfo.validations.typeOfWorkRequired"
                            :disabled="!dataInfo.validations.typeOfWorkVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/workType.svg"
                            height="14"
                            class="md-pl"
                          />Such Work Since
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_suchWorkSince_visible"
                            v-model="dataInfo.validations.suchWorkSinceVisible"
                            @change="sectionVisibilityChanged('suchWorkSinceVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_suchWorkSince_required"
                            v-model="dataInfo.validations.suchWorkSinceRequired"
                            :disabled="!dataInfo.validations.suchWorkSinceVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/workLocality.svg"
                            height="14"
                            class="md-pl"
                          />Work Locality
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_workLocality_visible"
                            v-model="dataInfo.validations.workLocalityVisible"
                            @change="sectionVisibilityChanged('workLocalityVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_workLocality_required"
                            v-model="dataInfo.validations.workLocalityRequired"
                            :disabled="!dataInfo.validations.workLocalityVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/employerName.svg"
                            height="14"
                            class="md-pl"
                          />Name of Employer
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_employer_visible"
                            v-model="dataInfo.validations.employerVisible"
                            @change="sectionVisibilityChanged('employerVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_employer_required"
                            v-model="dataInfo.validations.employerRequired"
                            :disabled="!dataInfo.validations.employerVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/transport.svg"
                            height="14"
                            class="md-pl"
                          />Means of Transport
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_meanOfTransport_visible"
                            v-model="dataInfo.validations.meanOfTransportVisible"
                            @change="sectionVisibilityChanged('meanOfTransportVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_meanOfTransport_required"
                            v-model="dataInfo.validations.meanOfTransportRequired"
                            :disabled="!dataInfo.validations.meanOfTransportVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/migrant.svg"
                            height="14"
                            class="md-pl"
                          />Migrant from (state)
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_migrantFrom_visible"
                            v-model="dataInfo.validations.migrantFromVisible"
                            @change="sectionVisibilityChanged('migrantFromVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_migrantFrom_required"
                            v-model="dataInfo.validations.migrantFromRequired"
                            :disabled="!dataInfo.validations.migrantFromVisible"
                          />
                        </b-col>
                      </b-row>
                    </app-collapse-item>
                    <app-collapse-item
                      :title="'Health & Education'"
                      class="bg-cu-green bo-cu-green"
                    >
                      <b-row>
                        <b-col cols="6">
                          <b>Field</b>
                        </b-col>
                        <b-col
                          cols="3"
                          class="text-right"
                        >
                          <b>Visible</b>
                        </b-col>
                        <b-col
                          cols="3"
                          class="text-right"
                        >
                          <b>Required</b>
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/education.svg"
                            height="14"
                            class="md-pl"
                          />Education
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_education_visible"
                            v-model="dataInfo.validations.educationVisible"
                            @change="sectionVisibilityChanged('educationVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_education_required"
                            v-model="dataInfo.validations.educationRequired"
                            :disabled="!dataInfo.validations.educationVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/skills.svg"
                            height="14"
                            class="md-pl"
                          />Skills
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_skills_visible"
                            v-model="dataInfo.validations.skillsVisible"
                            @change="sectionVisibilityChanged('skillsVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_skills_required"
                            v-model="dataInfo.validations.skillsRequired"
                            :disabled="!dataInfo.validations.skillsVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/handicap.svg"
                            height="14"
                            class="md-pl"
                          />Handicap
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_handicap_visible"
                            v-model="dataInfo.validations.handicapVisible"
                            @change="sectionVisibilityChanged('handicapVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_handicap_required"
                            v-model="dataInfo.validations.handicapRequired"
                            :disabled="!dataInfo.validations.handicapVisible"
                          />
                        </b-col>
                      </b-row>
                    </app-collapse-item>
                    <app-collapse-item
                      :title="'Membership'"
                      class="bg-cu-blue bo-cu-blue"
                    >
                      <b-row>
                        <b-col cols="6">
                          <b>Field</b>
                        </b-col>
                        <b-col
                          cols="3"
                          class="text-right"
                        >
                          <b>Visible</b>
                        </b-col>
                        <b-col
                          cols="3"
                          class="text-right"
                        >
                          <b>Required</b>
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/memberId.svg"
                            height="14"
                            class="md-pl"
                          />Member Id
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_member_visible"
                            v-model="dataInfo.validations.memberVisible"
                            @change="sectionVisibilityChanged('memberVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_member_required"
                            v-model="dataInfo.validations.memberRequired"
                            :disabled="!dataInfo.validations.memberVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/status.svg"
                            height="14"
                            class="md-pl"
                          />Membership Status
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_membershipStatus_visible"
                            v-model="
                              dataInfo.validations.membershipStatusVisible
                            "
                            @change="sectionVisibilityChanged('membershipStatusVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_membershipStatus_required"
                            v-model="
                              dataInfo.validations.membershipStatusRequired
                            "
                            :disabled="!dataInfo.validations.membershipStatusVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/cancelReason.svg"
                            height="14"
                            class="md-pl"
                          />Reason if Suspended
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_reasonIfSuspended_visible"
                            v-model="
                              dataInfo.validations.reasonIfSuspendedVisible
                            "
                            @change="sectionVisibilityChanged('reasonIfSuspendedVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_reasonIfSuspended_required"
                            v-model="
                              dataInfo.validations.reasonIfSuspendedRequired
                            "
                            :disabled="!dataInfo.validations.reasonIfSuspendedVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/memberSince.svg"
                            height="14"
                            class="md-pl"
                          />First Joining Date
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_joiningDate_visible"
                            v-model="dataInfo.validations.joiningDateVisible"
                            @change="sectionVisibilityChanged('joiningDateVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_joiningDate_required"
                            v-model="dataInfo.validations.joiningDateRequired"
                            :disabled="!dataInfo.validations.joiningDateVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/feesPaidTill.svg"
                            height="14"
                            class="md-pl"
                          />Fees Paid Till Date
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_paidTill_visible"
                            v-model="dataInfo.validations.paidTillVisible"
                            @change="sectionVisibilityChanged('paidTillVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_paidTill_required"
                            v-model="dataInfo.validations.paidTillRequired"
                            :disabled="!dataInfo.validations.paidTillVisible"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="pt-1"
                        >
                          <b-img
                            src="/images/members/feesPaidTill.svg"
                            height="14"
                            class="md-pl"
                          />Roles
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_roles_visible"
                            v-model="dataInfo.validations.rolesVisible"
                            @change="sectionVisibilityChanged('rolesVisible')"
                          />
                        </b-col>
                        <b-col
                          cols="3"
                          class="md-display-flex-end pt-1"
                        >
                          <b-form-checkbox
                            id="group_setting_roles_required"
                            v-model="dataInfo.validations.rolesRequired"
                            :disabled="!dataInfo.validations.rolesVisible"
                          />
                        </b-col>
                      </b-row>
                    </app-collapse-item>
                  </b-col>
                </b-row>
              </app-collapse-item>
            </app-collapse>
          </b-col>
          <b-col
            md="12"
            class="text-center mt-2"
          >
            <button
              class="btn btn-primary"
              @click="saveUnion"
            >
              Save
            </button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BForm, BImg, BFormCheckbox,
} from 'bootstrap-vue'
import InputGroup from '@/@core/layouts/shared/InputGroup.vue'
import SelectGroup from '@/@core/layouts/shared/SelectGroup.vue'
import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'
import {
  hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification,
} from '@/@core/comp-functions/ui/app'
import {
  appGetBlocks,
  appGetDistricts,
  appGetGroupSetting,
  appGetPanchayat,
  appGetStates,
  appGetSubDistrict,
  appGetUnionById,
  appSetGroupSetting,
  appUpdateUnion,
} from '@/@core/services/groups'
import { getUniqueNo, goToPath } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BCard,
    InputGroup,
    SelectGroup,
    AppCollapse,
    AppCollapseItem,
    BImg,
    BFormCheckbox,
  },
  data() {
    return {
      dataInfo: {
        id: getUniqueNo(),
        address: {},
        validations: {},
      },
      years: [
        new Date().getFullYear() - 1,
        new Date().getFullYear(),
        new Date().getFullYear() + 1,
      ].map(x => ({
        id: x,
        name: x,
      })),
      state: [],
      district: [],
      taluk: [],
      block: [],
      panchayat: [],
      category: this.$manMudra.constants.category,
      year: this.$manMudra.constants.months,
      period: this.$manMudra.constants.period,
      unionId: this.$route.params.unionId,
      isSubmitted: false,
    }
  },
  mounted() {
    this.getStates()
    if (this.unionId) {
      this.getUnionById()
      this.getUnionValidationById()
    }
  },
  methods: {
    saveUnion() {
      this.isSubmitted = true
      this.$nextTick(() => {
        if (window.ManMudra.validations.get()) {
          window.ManMudra.validations.focus()
          return
        }
        this.isSubmitted = false
        this.saveUnionDetails()
        this.saveGroupSetings()
      })
    },
    saveGroupSetings() {
      const validations = window.ManMudra.actions.cloneDeep({
        ...this.dataInfo.validations,
        unionId: this.dataInfo.id,
      })
      Object.keys(validations).forEach(x => {
        if (validations[x] == null) {
          validations[x] = false
        }
      })
      showLoader()
      appSetGroupSetting(validations)
        .then(({ data }) => {
          if (data.succeeded) {
            showSuccessNotification(this, data.message)
            this.getUnionValidationById()
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          hideLoader()
          showErrorNotification(this, error)
        })
    },
    saveUnionDetails() {
      const settings = window.ManMudra.actions.cloneDeep(this.dataInfo)
      delete settings.validations
      showLoader()
      appUpdateUnion(settings)
        .then(({ data }) => {
          if (data.succeeded) {
            goToPath(this, 'group-union-list')
            showSuccessNotification(this, data.message)
            this.getUnionById()
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          hideLoader()
          showErrorNotification(this, error)
        })
    },
    getUnionById() {
      showLoader()
      appGetUnionById({
        id: this.unionId,
      }).then(({ data }) => {
        if (data.succeeded) {
          // eslint-disable-next-line no-param-reassign
          data.data.feeInterval = 3
          if (!data.data.feeOf) {
            // eslint-disable-next-line prefer-destructuring, no-param-reassign
            data.data.feeOf = this.years[1].id
          }
          this.dataInfo = {
            ...this.dataInfo,
            ...data.data,
            countryId: 'in',
            internationalMember: 'NO',
          }
          this.setUnionFields()
        } else {
          showDangerNotification(this, data.message)
        }
        this.setAddressFields()
        hideLoader()
      })
    },
    setAddressFields() {
      if (this.dataInfo.address.stateId) {
        this.getDistricts()
      }
      if (this.dataInfo.address.districtId) {
        this.getSubDistricts()
        this.getBlock()
      }
      if (this.dataInfo.address.blockId) {
        this.getPanchayat()
      }
    },
    setUnionFields() {
      if (!this.dataInfo.address) {
        this.dataInfo.address = {}
      }
      if (!this.dataInfo.validations) {
        this.dataInfo.validations = {
          aadharOrMobile: false,
          nameVisible: false,
          nameRequired: false,
          surnameVisible: false,
          surnameRequired: false,
          organizationVisible: false,
          organizationRequired: false,
          landlinePhoneVisible: false,
          landlinePhoneRequired: false,
          relationVisible: false,
          relationRequired: false,
          aadharVisible: false,
          aadharRequired: false,
          mobileVisible: false,
          mobileRequired: false,
          stateVisible: false,
          stateRequired: false,
          districtVisible: false,
          districtRequired: false,
          tahsilVisible: false,
          tahsilRequired: false,
          categoryVisible: false,
          categoryRequired: false,
          casteVisible: false,
          casteRequired: false,
          pensionNoVisible: false,
          pensionNoRequired: false,
          jobCardVisible: false,
          jobCardRequired: false,
          janAadharVisible: false,
          janAadharRequired: false,
          shramikNoVisible: false,
          shramikNoRequired: false,
          typeOfWorkVisible: false,
          typeOfWorkRequired: false,
          suchWorkSinceVisible: false,
          suchWorkSinceRequired: false,
          workLocalityVisible: false,
          workLocalityRequired: false,
          employerVisible: false,
          employerRequired: false,
          meanOfTransportVisible: false,
          meanOfTransportRequired: false,
          migrantFromVisible: false,
          migrantFromRequired: false,
          educationVisible: false,
          educationRequired: false,
          skillsVisible: false,
          skillsRequired: false,
          handicapVisible: false,
          handicapRequired: false,
          memberVisible: false,
          memberRequired: false,
          membershipStatusVisible: false,
          membershipStatusRequired: false,
          reasonIfSuspendedVisible: false,
          reasonIfSuspendedRequired: false,
          joiningDateVisible: false,
          joiningDateRequired: false,
          paidTillVisible: false,
          paidTillRequired: false,
          blockRequired: false,
          blockVisible: false,
          panchayatVisible: false,
          panchayatRequired: false,
          townVillageVisible: false,
          townVillageRequired: false,
          pincodeVisible: false,
          pincodeRequired: false,
          emailVisible: false,
          emailRequired: false,
          ifscVisible: false,
          ifscRequired: false,
          incomeCatVisible: false,
          incomeCatRequired: false,
          underageVisible: false,
          underageRequired: false,
          earnAdultsVisible: false,
          earnAdultsRequired: false,
          nonEarningVisible: false,
          NonEarningRequired: false,
          rolesVisible: false,
          rolesRequired: false,
          houseNoVisible: false,
          houseNoRequired: false,
          localityVisible: false,
          localityRequired: false,
          postOfficeVisible: false,
          postOfficeRequired: false,
          bankVisible: false,
          bankRequired: false,
          rationTypeVisible: false,
          rationTypeRequired: false,
          rationNoVisible: false,
          maritalVisible: false,
          maritalRequired: false,
          sexVisible: false,
          sexRequired: false,
          ageVisible: false,
          ageRequired: false,
          dateOfBirthVisible: false,
          dateOfBirthRequired: false,
          ageOrDateOfBirthVisible: false,
          ageOrDateOfBirthRequired: false,
        }
      }
      if (!this.dataInfo.language) {
        this.dataInfo = {
          ...this.dataInfo,
          language: 'en-US',
        }
      }
    },
    getUnionValidationById() {
      appGetGroupSetting({
        id: this.unionId,
      }).then(({ data }) => {
        if (data.succeeded) {
          if (data.data.aadharOrMobile) {
            // eslint-disable-next-line no-param-reassign
            data.data.mobileRequired = null
            // eslint-disable-next-line no-param-reassign
            data.data.aadharRequired = null
          }
          this.dataInfo = {
            ...this.dataInfo,
            validations: data.data,
          }
        } else {
          showDangerNotification(this, data.message)
        }
      })
    },
    getStates() {
      appGetStates().then(({ data }) => {
        if (data.succeeded) {
          this.state = data.data
        } else {
          showDangerNotification(this, data.message)
        }
      })
    },
    getDistricts(reset) {
      if (reset) {
        this.dataInfo.address.districtId = null
        this.district = []
        this.taluk = []
        this.panchayat = []
        this.block = []
        this.dataInfo.address.subDistrictId = null
        this.dataInfo.address.blockId = null
        this.dataInfo.address.panchayatId = null
      }
      appGetDistricts({
        stateId: this.dataInfo.address.stateId,
      }).then(({ data }) => {
        if (data.succeeded) {
          this.district = data.data
        } else {
          showDangerNotification(this, data.message)
        }
      })
    },
    getSubDistricts(reset) {
      if (reset) {
        this.taluk = []
        this.block = []
        this.panchayat = []
        this.dataInfo.address.subDistrictId = null
        this.dataInfo.address.blockId = null
        this.dataInfo.address.panchayatId = null
      }
      appGetSubDistrict({
        districtId: this.dataInfo.address.districtId,
      }).then(({ data }) => {
        if (data.succeeded) {
          this.taluk = data.data
        } else {
          showDangerNotification(this, data.message)
        }
      })
    },
    getBlock(reset) {
      if (reset) {
        this.panchayat = []
        this.block = []
        this.dataInfo.address.panchayatId = null
        this.dataInfo.address.blockId = null
      }
      appGetBlocks({
        districtId: this.dataInfo.address.districtId,
      }).then(({ data }) => {
        if (data.succeeded) {
          this.block = data.data
        } else {
          showDangerNotification(this, data.message)
        }
      })
    },
    getPanchayat(reset) {
      if (reset) {
        this.panchayat = []
        this.dataInfo.address.panchayatId = null
      }
      appGetPanchayat({
        blockId: this.dataInfo.address.blockId,
      }).then(({ data }) => {
        if (data.succeeded) {
          this.panchayat = data.data
        } else {
          showDangerNotification(this, data.message)
        }
      })
    },
    sectionVisibilityChanged(control) {
      const requiredControlName = `${control.split('Visible')[0]}Required`
      if (
        !this.dataInfo.validations[control] && this.dataInfo.validations[requiredControlName]
      ) {
        this.dataInfo.validations[requiredControlName] = false
      }
    },
  },
}
</script>

<style lang="scss">
.app-group-setting {
  .bg-cu-blue .card-header {
    background: #e1f5fe !important;
  }

  .bg-yellow .card-header {
    background: #fffde7 !important;
  }

  .bg-orange .card-header {
    background: #fff3e0 !important;
  }
  .bg-lime .card-header {
    background: #e6ee9c !important;
  }
  .bo-lime {
    border-left: 3px solid #e6ee9c !important;
  }

  .bg-cyan .card-header {
    background: #e0f7fa !important;
  }
  .bg-cu-orange .card-header {
    background: #fbe5d6 !important;
  }

  .bg-cu-purple .card-header {
    background: #ede2f6 !important;
  }
  .bg-cu-green .card-header {
    background: #e2f0d9 !important;
  }
  .bg-cu-blue .card-header {
    background: #deebf7 !important;
  }
  .bo-cu-blue {
    border-left: 3px solid #deebf7 !important;
  }
  .bo-cu-green {
    border-left: 3px solid #e2f0d9 !important;
  }
  .bo-cu-purple {
    border-left: 3px solid #ede2f6 !important;
  }
  .bo-cu-orange {
    border-left: 3px solid #fbe5d6 !important;
  }
  .bo-cyan {
    border-left: 3px solid #e0f7fa !important;
  }
  .bo-blue {
    border-left: 3px solid #01a6ec !important;
  }

  .bo-yellow {
    border-left: 3px solid #c7c32f !important;
  }

  .bo-orange {
    border-left: 3px solid #f7bd52 !important;
  }
  .md-pl {
    padding-right: 10px;
  }
  .md-display-flex-end {
    display: flex;
    justify-content: end;
  }
  .bg-deep-pink .card-header {
    background: #fbe9e7;
  }
  .bo-pink {
    border-left: 3px solid #fbe9e7 !important;
  }

  .card-header[data-toggle="collapse"] {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .collapse .col-6 {
    display: flex;
  }
}
</style>
